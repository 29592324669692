
* {
  font-family: 'Exo', sans-serif;
}

.welcome-party-root {
  background: #FEFBFF;
}

.welcome-party-root * {
  font-family: 'Archivo', sans-serif;
  color: #212E47;
}

.welcome-party-root .auth-info-container .collapse-icon.closed .dropdown-bg {
  display: none;
}

.welcome-party-root .auth-info-container .collapse-icon.closed .dropdown-body {
  display: none;
}

.searchable-dropdown-item:hover {
  background: #E0E0FF;
}

@media screen and (max-height: 800px) {
  .bottom-bar {
    height: 45px !important;
  }
}

@media screen and (max-width: 1000px) {
  .welcome-party-root .auth-info-container.dashboard {
    flex-direction: column;
    align-items: flex-end !important;
  }

  .welcome-party-root .auth-info-container.dashboard > button {
    margin-bottom: 10px;
    margin-right: 0 !important;
  }

  .welcome-party-root .auth-info-container.dashboard .dropdown-body {
    top: 80px !important;
  }
}

@media screen and (max-width: 900px) {
  .welcome-party-root.login > div:first-child {
    width: 100% !important
  }
  .welcome-party-root.login > div:last-child {
    display: none
  }
}

@media screen and (max-width: 450px) {
  .welcome-party-root.login > div:first-child {
    transform: scale(0.8)
  }

  .welcome-party-root.login > div:first-child div > img {
    width: 330px
  }
}

@media screen and (max-width: 700px) {
  .welcome-party-root .top-bar {
    flex-wrap: wrap;
    align-items: center !important;
  }
}

.dashboard-sidebar .collapse-icon {
  display: none;
  cursor: 'pointer'
}

@media screen and (max-width: 900px) {
  .dashboard-automation-container {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .dashboard-automation-container > div:last-child {
    margin-top: 20px
  }
}

@media screen and (max-width: 650px) {
  .welcome-party-root .community-name-container > div {
    height: 39px;
    width: 55px;
    overflow-x: clip;
    margin-bottom: -10px;
  }

  .ftux-success-popup {
    width: 100vw !important;
    min-width: 100vw !important;
  }

  .ftux-success-popup img {
    display: none;
  }

  .ftux-success-popup-bg {
    background: rgba(0,0,0,.35) !important
  }

  .dashboard-sidebar .collapse-icon {
    display: block;
  }

  .dashboard-sidebar.closed .nav-item {
    display: none;
  }
}

@media screen and (max-width: 740px) {
  .ftux-steps-container {
    min-width: 540px !important;
  }

  .ftux-steps-container > div {
    width: 500px !important;
  }

  .ftux-steps-list .separator {
    width: 40px !important;
  }
}

@media screen and (max-width: 540px) {
  .ftux-steps-container {
    min-width: 370px !important;
  }

  .ftux-steps-container > div {
    width: 350px !important;
  }

  .ftux-steps-container .collapse-icon {
    display: block !important;
  }

  .ftux-steps-list .separator {
    width: 1px !important;
    height: 10px !important;
    margin-bottom: 10px;
    margin-left: 20px;
  }

  .ftux-steps-list {
    flex-direction: column;
    margin-top: 15px !important;
    margin-bottom: 10px !important;
  }

  .step-body h3, .step-body > div {
    margin-top: 10px !important
  }

  .ftux-steps-list .step-item {
    flex-direction: row !important;
    width: 150px !important;
  }

  .ftux-steps-list .step-item b {
    margin: 0;
    text-align: left;
    margin-bottom: 2px;
  }

  .ftux-steps-list .step-item > div {
    margin-right: 5px;
  }

  .ftux-steps-list.closed .separator {
    display: none;
  }

  .ftux-steps-list:not(.closed) + .step-body {
    display: none;
  }

  .ftux-steps-list.closed .step-item.current {
    margin-left: 0 !important
  }

  .ftux-steps-list.closed .step-item:not(.current) {
    display: none !important;
  }

  .first-captcha-block {
    margin-right: 20px !important;
  }
}

@media screen and (max-width: 370px) {
  .ftux-steps-container {
    min-width: 340px !important;
  }

  .ftux-steps-container > div {
    width: 300px !important;
  }
}

a:visited {
  color: #5865F2 !important
}

.welcome-party-root a {
  color: #5865F2 !important;
  margin: 20px;
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  border-bottom: 1px solid #5865F2;
  opacity: 0.9;
}

.welcome-party-root a:hover {
  opacity: 1;
}

.welcome-party-root a.white {
  color: white !important;
}

.welcome-party-root input[type=text] {
  background: rgba(63, 76, 218, 0.05);
  border: 1px solid #E0E0FF;
  box-shadow: none;
  height: 44px;
  font-size: 14px;
  padding-left: 20px
}

.welcome-party-root input[type=text]::placeholder {
  color: #91909A
}

.welcome-party-root input[type=text]:focus {
  outline: none;
}

.welcome-party-root button {
  background: #5865F2;
  font-size: 14px;
  font-weight: 700;
  color: white;
  padding: 10px 15px;
  user-select:none;
  opacity: 0.97;
}

.welcome-party-root button:hover {
  opacity: 1;
}

.need-help-container {
  opacity: 0.85;
  cursor: pointer;
}

.need-help-container:hover {
  opacity: 1;
}

.welcome-party-root button[disabled] {
  background: #777680;
  pointer-events: none;
}

.welcome-party-root .discord-preview * {
  color: white !important;
  font-family: 'Whitney', sans-serif;
  /* font-family: 'Whitney Semibold', sans-serif;
  font-family: 'Whitney Book', sans-serif;
  font-family: 'Whitney Light', sans-serif; */
}

h3 {
  font-size: 24px;
}

button {
  background: #5865F2;
  border-radius: 5px;
  padding: 10px 20px;
  border: none;
  font-weight: 700;
  color: white;
  cursor: pointer;
}

button.secondary {
  color: #5865F2;
  background: white;
  font-weight: normal;
}

button.secondary:hover {
  background: rgba(0,0,0,0.15)
}

button.text {
  color: black;
  background: transparent;
  font-weight: normal;
}

.root {
  text-align: center;
}

.root-container {
  width: 90%;
  max-width: 1240px;
  display: inline-block;
}

a {
  cursor: pointer;
}

input[type=text] {
  background: #FFFFFF;
  border: 1px solid #F0EFEF;
  box-sizing: border-box;
  box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.15);
  min-height: 35px;
  padding-left: 5px
}

.tooltip-label + .tooltip-text {
  display: none;
}

.tooltip-label:hover + .tooltip-text {
  display: block;
  position: absolute;
  background: rgb(245, 246, 253);
  color: black;
  top: 70px;
  z-index: 99999999;
  font-size: 14px;
  font-weight: 500;
  padding: 20px;
  width: 200%;
  border-radius: 10px;
  border: 2px solid #BEC2FF;
}

.no-outline:focus {
  outline: none;
}

.tag-hover {
  background: rgba(88, 101, 242, 0.075) !important
}

.tag-hover:hover {
  background: rgba(88, 101, 242, 0.15) !important
}

.tag-hover-selected {
  background: rgba(88, 101, 242, 0.15) !important
}

.tag-hover-selected:hover {
  background: rgba(88, 101, 242, 0.11) !important
}

.gray-hover:hover {
  background: rgba(88, 101, 242, 0.07) !important
}

textarea {
  background: #FFFFFF;
  border: 1px solid #F0EFEF;
  box-sizing: border-box;
  box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.15);
  height: 35px;
  padding: 7px;
}

.qa-card {
  background: #FFFFFF;
  box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: calc(100% - 40px);
  padding-right: 10px;
  padding-top: 10px;
  padding-left: 15px;
  padding-bottom: 15px;
}

@media screen and (max-width: 1080px) {
  .auth-info-container .avatar {
    width: 25px !important;
    height: 25px !important
  }

  .top-bar:not(.welcome-party-root .top-bar) {
    flex-wrap: wrap;
  }

  .search-bar {
    flex-basis: 100%;
  }

  .search-bar input {
    width: 100%;
    margin-top: 20px;
  }

  .qa-card .right-column {
    max-width: 100px;
  }

  .qa-card .left-column {
    min-width: 100px !important;
  }

  .pagination {
    flex-direction: column;
    align-items: flex-start !important;
    height: 100px
  }
}

@media screen and (max-width: 800px) {
  .center-column {
    width: unset !important;
    margin-right: 10px;
    font-size: 12px !important;
  }

  .right-column {
    align-self: flex-end;
  }

  .right-column * {
    font-size: 12px !important;
  }

  .left-column {
    font-size: 12px !important;
  }

  .auth-info-container:not(.welcome-party-root .auth-info-container) {
    flex-basis: 100%;
    margin-top: 10px;
    justify-content: flex-end;
  }

  .qa-card .avatar {
    width: 25px !important;
    height: 25px !important;
    top: 0 !important;
  }

  /* .qa-card .avatar.asker {
    top: 20px !important;
  } */

  .qa-card .avatar + div {
    display: block !important
  }

  .root-container {
    width: calc(100% - 30px)
  }

  .qa-card .container {
    flex-direction: column-reverse;
  }

  .qa-card .container > div:first-child {
    max-width: 100% !important
  }
}

@media screen and (max-width: 500px) {
  .center-column {
    font-size: 11px !important;
  }

  .right-column * {
    font-size: 11px !important;
  }

  .left-column {
    font-size: 11px !important;
    min-width: 70px !important;
  }

  .pagination * {
    font-size: 11px !important;
  }

  button.text {
    padding: 5px 6px !important;
  }
}